
import {defineComponent} from "vue";
import HomeInfoSubBlock from "@/views/home/HomeProfile/HomeInfoSubBlock.vue";

export default defineComponent({
  setup(props) {
    let myTitle = props.title;
    if (typeof props.title === "string") {
      myTitle = props.title[0].toUpperCase() + props.title.slice(1);
    }
    const data = {
      title: myTitle,
      values: props.values
    }
    return {data}
  },
  props: {
    title: {
      type: [String, Number],
      required: true
    },
    values: {
      type: [Array, Object],
    }
  },
  components: {
    HomeInfoSubBlock
  }
});
