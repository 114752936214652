import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar_item = _resolveComponent("nav-bar-item")!
  const _component_nav_bar_animation = _resolveComponent("nav-bar-animation")!
  const _component_nav_bar = _resolveComponent("nav-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_nav_bar, null, {
      default: _withCtx(() => [
        _createVNode(_component_nav_bar_item, {
          class: "name-nav",
          path: "/"
        }, {
          text: _withCtx(() => [
            _createTextVNode("boxhezi")
          ]),
          _: 1
        }),
        _createVNode(_component_nav_bar_item, { class: "placeholder-nav" }, {
          text: _withCtx(() => [
            _createVNode(_component_nav_bar_animation)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}