import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.value.link,
    target: "_blank"
  }, [
    _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-link" }),
    (_ctx.value.linkTitle !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value.linkTitle), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value.link), 1))
  ], 8, _hoisted_1))
}