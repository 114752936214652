
import {defineComponent} from "vue";

import HomeSummary from "@/views/home/HomeProfile/HomeSummary.vue";
import ProfileLinks from "./HomeProfile/ProfileLinks.vue";
import HomeInfoBlock from "./HomeProfile/HomeInfoBlock.vue";

// import {start} from "./dataParser";
import {default as resumeData} from "./data.json";

export default defineComponent({
  setup() {
    // onMounted(() => {
    //   start();
    // });
    return {
      resumeData
    };
  },
  props: {
    title: {
      type: String,
    }
  },
  components: {
    HomeSummary,
    ProfileLinks,
    HomeInfoBlock
  }
});
