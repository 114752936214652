import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db124814"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      href: _ctx.link,
      target: "_blank"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ]),
        _createElementVNode("span", null, [
          _renderSlot(_ctx.$slots, "text", {}, undefined, true)
        ])
      ])
    ], 8, _hoisted_1)
  ]))
}