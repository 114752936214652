
import {defineComponent} from "vue";

export default defineComponent({
    setup(props) {
      let value = {
        linkTitle: props.linkData?.linkTitle,
        link: props.linkData?.link
      };
      return {value};
    },
    props: {
      linkData: {
        type: [Object],
        require: true
      }
    }
  }
);

