
import {defineComponent} from "vue";
import SubBlockLink from "@/views/home/HomeProfile/SubBlockLink.vue";

export default defineComponent({
  setup(props) {
    const data = {
      contentTitle: props.value.contentTitle,
      timeframe: props.value.timeframe,
      subtitle: props.value.subtitle,
      contents: props.value.contents,
      links: props.value.links
    };
    return {data};
  },
  props: {
    value: {
      type: [Object],
      required: true
    }
  },
  components: {
    SubBlockLink
  }
});
