import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b4faa1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contentTitleContainer"
}
const _hoisted_2 = { class: "contentTitle" }
const _hoisted_3 = {
  key: 0,
  class: "timeframe"
}
const _hoisted_4 = {
  key: 1,
  class: "subtitle"
}
const _hoisted_5 = {
  key: 2,
  class: "contents"
}
const _hoisted_6 = {
  key: 3,
  class: "links"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_block_link = _resolveComponent("sub-block-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.data.contentTitle !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.contentTitle), 1),
          (_ctx.data.timeframe !== undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.data.timeframe), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.subtitle !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.data.subtitle), 1))
      : _createCommentVNode("", true),
    (_ctx.data.contents !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.contents, (item) => {
              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(item), 1))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.links !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.links, (link) => {
              return (_openBlock(), _createElementBlock("li", null, [
                _createVNode(_component_sub_block_link, { linkData: link }, null, 8, ["linkData"])
              ]))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}