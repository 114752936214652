
import { computed, defineComponent } from "@vue/runtime-core";
// import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "NavBarItem",
  props: {
    path: {
      default: null,
      type: String,
    },
  },
  // setup(props) {
  //   const router = useRouter();
  //   const route = useRoute();

  //   let isActive = computed(() => {
  //     return route.path.indexOf(props.path) !== -1;
  //   });

  //   function handleClick() {
  //     if (route.path !== null) {
  //       router.push(props.path);
  //     }
  //   }

  //   return {
  //     handleClick,
  //     isActive
  //   };
  // }
});
