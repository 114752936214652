import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb57adee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "full-command" }
const _hoisted_2 = {
  key: 0,
  class: "prefix"
}
const _hoisted_3 = {
  key: 1,
  class: "prefix"
}
const _hoisted_4 = { class: "command" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, [
      (!_ctx.rootUser)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.prefix), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.rootPrefix), 1)),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.displayCommand) + "|", 1)
    ])
  ]))
}