
import { defineComponent } from "@vue/runtime-core";
import NavBar from "./NavBar.vue";
import NavBarItem from "./NavBarItem.vue";
import NavBarAnimation from "./NavBarAnimation.vue";

export default defineComponent({
  name: "MainNavBar",
  components: {
    NavBar,
    NavBarItem,
    NavBarAnimation
  }
});
